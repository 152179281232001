import React from "react";
import { Link } from "gatsby";
import { useAppContext } from "../../context/appProvider";
// import DownloadComponent from "./components/DownloadComponent";
import * as Styled from "./styled";

const CartLineItem = (props) => {
  const { lineItem } = props;
  const ctx = useAppContext();
  const { dispatch } = ctx;

  return (
    <Styled.TableRow key={lineItem.id}>
      <td className="title" id={lineItem.id}>
        <button
          onClick={() => {
            dispatch({
              type: "REMOVE-FROM-CART",
              payload: {
                id: lineItem.id,
              },
            });
          }}
        >
          x
        </button>
        <Link to={lineItem.slug}>{lineItem.title}</Link>
      </td>
      <td className="price">{lineItem.price}</td>

      <td className="remove-from-cart"></td>
    </Styled.TableRow>
  );
};

export default CartLineItem;
