import styled from "styled-components";
import { theme } from "../../theme/variables";
import MEDIA from "../../theme/helpers/mediaTemplates";
import { Link } from "gatsby";

export const Button = styled.button`
  padding: 8px 22px 9px;
  margin: 4px 8px;
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.halfUp},
    ${(props) => props.theme.primary}
  );
  border-radius: 30px;
  display: block;
  font-weight: 700;
  box-shadow: 5px 5px 7px ${(props) => props.theme.default_primary},
    -2px -2px 4px ${(props) => props.theme.lighterOne};
  color: ${(props) => props.theme.default_lighterTwo};
  border: none;
  font-size: 1.2rem;
  text-align: center;
  font-family: ${theme.fontHeaders};
  cursor: pointer;

  &:hover {
    background: linear-gradient(
      135deg,
      ${(props) => props.theme.darkerTwo},
      ${(props) => props.theme.darkerOne}
    );
  }
`;
export const ButtonSecondary = styled(Link)`
  padding: 8px 22px;
  background: none;
  border-radius: 30px;
  border: 2px solid ${(props) => props.theme.primary};
  display: block;
  font-weight: 300;
  box-shadow: none;
  color: ${(props) => props.theme.primary};
  font-size: 0.9rem;
  text-align: center;

  &:hover {
    border: 2px solid ${(props) => props.theme.darkerTwo};
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${MEDIA.PHONE`
		flex-direction: column;
			
    `}
`;
