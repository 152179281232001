import React from "react";
import { graphql } from "gatsby";
import CartTemplate from "../../templates/cart";

const environment = {
  lang: "en",
  path: "/en/cart",
  altHrefLang: [
    {
      lang: "en",
      path: "/en/cart",
    },
  ],
  theme: "blue",
};
const CartEn = (props) => {
  return <CartTemplate {...props} environment={environment} />;
};

export default CartEn;

export const CartQueryEn = graphql`
  {
    imgDesktop: file(relativePath: { eq: "index-banner-desktop-en.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imgMobile: file(relativePath: { eq: "index-banner-mobile-en.png" }) {
      childImageSharp {
        gatsbyImageData(width: 820, layout: CONSTRAINED)
      }
    }
  }
`;
